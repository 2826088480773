.btn-top-results {
	position: fixed;
	top: 0;
	left: 0;
	right: none;
	margin: 0 5px;
	padding: 5px 10px;
	background: linear-gradient(183deg, orange 0%, rgb(185, 122, 5) 70%);
	color: white;
	border: none;
	border-radius: 0 0 10px 10px;
	box-shadow: inset 0 0 5px 0 black;
	transition: left 0.5s;
	cursor: pointer;
	z-index: 3;
}

.animation-btn-top-results {
	left: calc(100% - (185px));
}

.box-top-results {
	position: fixed;
	top: -100%;
	left: 0;
	width: 100%;
	padding: 50px 10px 20px;
	background: radial-gradient(
		ellipse,
		rgba(126, 47, 175, 1) 10%,
		rgba(32, 24, 37, 1) 90%
	);
	border-bottom: 3px ridge #bebebe;
	transition: top 0.5s;
	z-index: 2;
}
.animation-box-top-results {
	top: 0;
}

.box-top-results p {
	text-align: center;
	color: white;
}

.list-top-results {
	width: 100%;
	font-size: 1.6rem;
	border: 1px solid black;
	font-family: Arial, Helvetica, sans-serif;
}

.list-top-results thead {
	text-transform: uppercase;
	color: white;
	background-color: #1b1b1ba8;
}

.list-top-results th {
	padding: 5px 0;
}

.list-top-results tbody th {
	font-weight: normal;
	color: rgb(255, 255, 255);
	background-color: #ffffff12;
}

@media (min-width: 575px) {
}
@media (min-width: 768px) {
	.list-top-results {
		font-size: 2rem;
	}
}
@media (min-width: 992px) {
}
