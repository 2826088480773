.box-question {
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
	width: 100%;
	padding: 40px 20px;
	z-index: 1;
}

.box-answers {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
}

.box-answers button {
	text-align: left;
	color: white;
	cursor: pointer;
	transition: 0.5s ease;
}

.box-answers button:hover {
	box-shadow: 0px 0px 15px 0px #154d78;
}

.question,
.box-answers button {
	position: relative;
	margin: 5px 0;
	padding: 10px 18px;
	background: linear-gradient(
		126deg,
		rgba(20, 19, 21, 1) 0%,
		rgba(9, 10, 13, 1) 83%
	);
	border: 3px solid #196298;
	border-radius: 40px;
	box-shadow: inset 0px 0px 10px 0px #154d78;
}

.question::after,
.box-answers button::after {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	height: 3px;
	width: 100vw;
	transform: translate(-50%, -50%);
	background-color: #196298;
	z-index: -10;
}

.question {
	margin: 10px 0;
	padding: 14px 20px 18px 20px;
	text-align: center;
	color: white;
}

.box-answers .correct-answers {
	border: 3px solid #3d7815;
	box-shadow: inset 0px 0px 15px 0px #3d7815;
}
.box-answers .incorrect-answers {
	border: 3px solid #751f0c;
	box-shadow: inset 0px 0px 15px 0px #751f0c;
}

@media (min-width: 575px) {
	.box-answers {
		flex-direction: row;
	}
	.box-answers button {
		width: 50%;
	}
}
@media (max-height: 680px) and (min-width: 768px) {
	.question,
	.box-answers {
		width: 50%;
	}
	.box-question {
		align-items: center;
	}
}
@media (min-width: 768px) {
}
@media (min-width: 992px) {
}
