.tooltip-game-control-buttons {
	position: relative;
	display: inline-block;
	margin: 5px;
}

.game-control-buttons,
.tooltip-game-control-buttons button {
	padding: 5px 10px;
	border: 1px solid black;
	text-decoration: none;
	border-radius: 10px;
	color: white;
	text-transform: uppercase;
	background: linear-gradient(26deg, rgb(40, 39, 40) 0%, rgba(9, 10, 13, 1) 83%);
	transition: transform 0.3s;
	cursor: pointer;
}

.tooltip-game-control-buttons:hover {
	transform: scale(1.02);
}

.tooltip-game-control-buttons .tooltip-text-game-control-buttons {
	visibility: hidden;
	width: 160px;
	background-color: #333;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px;
	position: absolute;
	z-index: 1;
	bottom: 125%;
	left: 50%;
	transform: translateX(-50%);
	opacity: 0;
	transition: opacity 0.8s, visibility 0.2s;
}

.tooltip-game-control-buttons:hover .tooltip-text-game-control-buttons {
	visibility: visible;
	opacity: 1;
}
