.box-app {
	position: relative;
	min-height: 100vh;
}

.background-question {
	position: relative;
	background-image: url(../../image/background.jpg);
	background-size: cover;
	background-position: center;
	min-height: 350px;
}

.background-question::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: #00000077;
	z-index: 0;
}

@media (min-width: 575px) {
	.box-app {
		display: flex;
	}
	.background-question {
		flex-grow: 1;

		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}
}
@media (max-height: 680px) {
	.box-app {
		display: flex;
		flex-direction: column;
	}
}
@media (min-width: 768px) {
}
@media (min-width: 992px) {
}
