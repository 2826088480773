.box-results {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	width: 80%;
	height: 90%;
	padding: 10px;
	list-style: none;
	color: orange;
	z-index: 0;
}

.cash-results {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin: 5px 0;
	align-items: center;
}

.safe-cash-results {
	color: white;
}

.cash-active-results {
	box-sizing: content-box;
	position: relative;
	padding: 5px 20px;
	margin: -1px;
	background: linear-gradient(183deg, orange 0%, rgb(185, 122, 5) 70%);
	color: white;
	border: 1px solid white;
	border-radius: 25px;
	box-shadow: inset 0 0 5px 0 black, 0 0 2px 0 black;
}

.cash-active-results::after {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 2px;
	width: 200%;
	background-color: white;
	box-shadow: 0 0 2px 0 black;
	z-index: -1;
}

@media (min-width: 575px) {
}
@media (max-height: 680px) and (min-width: 768px) {
	.box-results {
		width: 50%;
	}
}
@media (min-width: 768px) {
}
@media (min-width: 992px) {
}
