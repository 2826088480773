.box-finish-game {
	position: relative;
	display: flex;
	align-items: center;
	flex-direction: column;
	color: white;
	z-index: 1;
}

.box-finish-game > div {
	text-align: center;
	width: 95%;
	padding: 20px 10px;
	margin: 50px 0 30px;
	background: radial-gradient( ellipse, rgba(126, 47, 175, .8) 10%, rgba(32, 24, 37, .8) 90% );
}

.box-finish-game > div > p {
	margin-bottom: 10px;
}

.box-finish-game div h2 {
	margin: 10px 0 20px;
	text-transform: uppercase;
	font-size: 3rem;
}
.results-finish-game {
	width: 80%;
	margin-left: auto;
	margin-right: auto;
}

.results-finish-game tr {
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid rgba(176, 176, 176, 0.312);
	margin: 10px 0;
}

.finish-game-get-name{
	display: flex;
	flex-direction: column;
	align-items: center;
}

.finish-game-get-name > input {
	padding: 8px 10px;
	margin: 5px 0;
	font-size: 2rem;
	border: 1px solid black;
	border-radius: 10px;
	cursor: pointer;
}

@media (min-width: 575px) {
	.box-finish-game > div {
		margin-bottom: 50px;
		padding: 30px 20px 40px;
	}
}
@media (max-height: 680px) and (min-width: 768px) {
	.results-finish-game {
		width: 50%;
	}
}
@media (min-width: 768px) {
}
@media (min-width: 992px) {
}
