* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

html {
	font-size: 62.5%;
	scroll-behavior: smooth;
}

p,
span,
button,
a,
table {
	font-size: 2rem;
}

a{
	font-family: Arial, Helvetica, sans-serif;
}

h1 {
	font-size: 2.5rem;
}
h2 {
	font-size: 2.2rem;
}

body {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
