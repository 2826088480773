.box-side-panel {
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	padding: 10px;
	border-top: 3px ridge #bebebe;
	background: rgb(126, 121, 153);
	background: radial-gradient(
		ellipse at left,
		rgba(126, 47, 175, 1) 10%,
		rgba(32, 24, 37, 1) 90%
	);
}

@media (min-width: 575px) {
	.box-side-panel {
		min-width: 280px;
		border-top: none;
		border-left: 3px ridge #bebebe;
	}
}
@media (max-height: 680px) {
	.box-side-panel {
		border-top: 3px ridge #bebebe;
		border-left: none;
	}
}
@media (min-width: 768px) {
	.box-side-panel {
		min-width: 300px;
	}
}
@media (min-width: 992px) {
	.box-side-panel {
		min-width: 350px;
	}
}
